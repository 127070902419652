import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import store from "@/store";

Vue.use(VueRouter);
Vue.use(VueMeta);
const moment = require("moment");
require("moment/locale/id");

Vue.use(require("vue-moment"), {
    moment,
});

const routes = [
    {
        path: "/",
        name: "Login",
        component: () =>
            import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },

    // Laporan
    {
        path: "/laporanScanner",
        name: "Laporan_Scanner",
        component: () =>
            import(
                /* webpackChunkName: "laporanScanner" */ "../views/Laporan/LaporanScanner.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/laporanInbound",
        name: "Laporan_Inbound",
        component: () =>
            import(
                /* webpackChunkName: "laporanInbound" */ "../views/Laporan/LaporanInbound.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/laporanOutbound",
        name: "Laporan_Outbound",
        component: () =>
            import(
                /* webpackChunkName: "laporanOutbound" */ "../views/Laporan/LaporanOutbound.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/laporanStockOpname",
        name: "Laporan_Stock_Opname",
        component: () =>
            import(
                /* webpackChunkName: "laporanStockOpname" */ "../views/Laporan/LaporanStockOpname.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/laporanInTransit",
        name: "Laporan_In_Transit",
        component: () =>
            import(
                /* webpackChunkName: "laporanInTransit" */ "../views/Laporan/LaporanInTransit.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/masterBarcode",
        name: "Master_Barcode",
        component: () =>
            import(
                /* webpackChunkName: "masterBarcode" */ "../views/Laporan/MasterBarcode.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },

    {
        path: "/laporanStock",
        name: "Laporan_Stock",
        component: () =>
            import(
                /* webpackChunkName: "laporanInTransit" */ "../views/Laporan/LaporanStock.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },

    //Transaksi
    {
        path: "/terimaData",
        name: "Termia_Data",
        component: () =>
            import(
                /* webpackChunkName: "terimaDataAirMancur" */ "../views/Transaksi/TerimaDataAirMancur.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiAntarGudang",
        name: "Transaksi_Antar_Gudang",
        component: () =>
            import(
                /* webpackChunkName: "transaksiAntarGudang" */ "../views/Transaksi/AntarGudang.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiSales",
        name: "Transaksi_Sales",
        component: () =>
            import(
                /* webpackChunkName: "transaksiSales" */ "../views/Transaksi/Sales.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiEvent",
        name: "Transaksi_Event",
        component: () =>
            import(
                /* webpackChunkName: "transaksiSales" */ "../views/Transaksi/Event.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiSampel",
        name: "Transaksi_Sampel",
        component: () =>
            import(
                /* webpackChunkName: "transaksiSampel" */ "../views/Transaksi/Sampel.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiDonasi",
        name: "Transaksi_Donasi",
        component: () =>
            import(
                /* webpackChunkName: "transaksiDonasi" */ "../views/Transaksi/Donasi.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiStockOpname",
        name: "Transaksi_Stock_Opname",
        component: () =>
            import(
                /* webpackChunkName: "transaksiStockOpname" */ "../views/Transaksi/StockOpname.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/transaksiReturStock",
        name: "Transaksi_Retur_Stock",
        component: () =>
            import(
                /* webpackChunkName: "transaksiStockOpname" */ "../views/Transaksi/TpReturToStock.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/print-barcode",
        name: "Transaksi_Print_Barcode",
        component: () =>
            import(
                /* webpackChunkName: "masterDataRole" */ "../views/Transaksi/PrintBarcode.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/print-barcode/:id",
        name: "Transaksi_Print_Barcode_Detail",
        component: () =>
            import(
                /* webpackChunkName: "masterDataRole" */ "../views/Transaksi/PrintBarcodeDetail.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },

    //Master Data
    {
        path: "/gudang",
        name: "Master_Data_Gudang",
        component: () =>
            import(
                /* webpackChunkName: "masterDataGudang" */ "../views/Data/Gudang.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/tipeGudang",
        name: "Master_Data_Tipe_Gudang",
        component: () =>
            import(
                /* webpackChunkName: "masterDataTipeBox" */ "../views/Data/TipeGudang.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/tipeBox",
        name: "Master_Data_Tipe_Box",
        component: () =>
            import(
                /* webpackChunkName: "masterDataTipeBox" */ "../views/Data/TipeBox.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/tipePenerimaan",
        name: "Master_Data_Tipe_Penerimaan",
        component: () =>
            import(
                /* webpackChunkName: "masterDataTipePenerimaan" */ "../views/Data/TipePenerimaan.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/tipePengeluaran",
        name: "Master_Data_Tipe_Pengeluaran",
        component: () =>
            import(
                /* webpackChunkName: "masterDataTipePengeluaran" */ "../views/Data/TipePengeluaran.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/userWeb",
        name: "Data_User_Web",
        component: () =>
            import(
                /* webpackChunkName: "dataUserWeb" */ "../views/Data/UserWeb.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/userBarcode",
        name: "Data_User_Barcode",
        component: () =>
            import(
                /* webpackChunkName: "dataUserBarcode" */ "../views/Data/UserBarcode.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/customer",
        name: "Master_Data_Customer",
        component: () =>
            import(
                /* webpackChunkName: "masterDataCustomer" */ "../views/Data/Customer.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/devices",
        name: "Master_Data_Handheld",
        component: () =>
            import(
                /* webpackChunkName: "masterDataHandheld" */ "../views/Data/Devices.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/material",
        name: "Master_Data_Material",
        component: () =>
            import(
                /* webpackChunkName: "masterDataMaterial" */ "../views/Data/Material.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/signature",
        name: "Master_Data_Signature",
        component: () =>
            import(
                /* webpackChunkName: "masterDataSignature" */ "../views/Data/Signature.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
    {
        path: "/role",
        name: "Master_Data_Role",
        component: () =>
            import(
                /* webpackChunkName: "masterDataRole" */ "../views/Data/Role.vue"
            ),
        beforeEnter: (to, from, next) => {
            if (!store.getters["auth/authenticated"]) {
                return next({
                    name: "Login",
                });
            }
            next();
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
