import axios from "axios";
import jwt_decode from "jwt-decode";

export default {
    namespaced: true,

    state: {
        token: null,
        refresh: null,
        user: null,
        custom_headers: null,
    },

    getters: {
        authenticated(state) {
            return state.token && state.user;
        },
        getRefresh(state) {
            return state.refresh;
        },
        user(state) {
            return state.user;
        },
        custom_headers(state) {
            return state.custom_headers;
        },
    },

    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
        },
        SET_REFRESH(state, refresh) {
            state.refresh = refresh;
        },
        SET_USER(state, data) {
            state.user = data;
        },
        SET_CUSTOM_HEADERS(state, data) {
            state.custom_headers = data;
        },
    },

    actions: {
        async logIn({ commit, dispatch }, credentials) {
            let response = await axios.post("v1/admin/login", credentials, {
                headers: {
                    "Content-Type": "application/json",
                },
            });
            commit("SET_REFRESH", response.data.data.tokenRefresh);
            return dispatch("attempt", response.data.data.token);
        },

        async refresh({ commit, dispatch }, credentials) {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${credentials}`;
            let response = await axios.post("v1/user/refresh");
            commit("SET_REFRESH", response.data.data.tokenRefresh);
            return dispatch("attempt", response.data.data.token);
        },

        async attempt({ commit, state, dispatch }, token) {
            var decoded;
            if (token) {
                commit("SET_TOKEN", token);
                decoded = jwt_decode(token);
                console.log(decoded);
            }

            if (!state.token) {
                return;
            }

            try {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${token}`;
                await axios
                    .get("v1/user_web/profile")
                    .then((response) => {
                        commit("SET_USER", response.data.data);
                    })
                    .catch((error) => {
                        if (error.response) {
                            if (error.response.status === 401) {
                                return dispatch(
                                    "refresh",
                                    localStorage.getItem("refresh")
                                );
                            }
                        }
                    });
            } catch (e) {
                commit("SET_TOKEN", null);
                commit("SET_REFRESH", null);
                commit("SET_USER", null);
            }
        },

        logOut({ commit }) {
            // return axios.post('logout').then(() => {
            //     commit('SET_TOKEN', null)
            // })
            commit("SET_TOKEN", null);
            commit("SET_REFRESH", null);
            commit("SET_USER", null);
        },

        customHeaders({ commit }, data) {
            commit("SET_CUSTOM_HEADERS", data);
        },
    },
};
