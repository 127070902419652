export default {
    computed: {
        response_headers() {
            return this.$store.getters["auth/custom_headers"] || {};
        },
    },
    methods: {
        isMeta(items = []) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].is_meta) return true;
            }

            return false;
        },
        textGrandTotal(items = []) {
            let prefix = " (Dalam botol)";
            let is_meta = false;
            for (let i = 0; i < items.length; i++) {
                if (items[i].is_meta) {
                    is_meta = true;
                    break;
                }
            }
            if (is_meta) prefix = "";
            return "Grand Total" + prefix;
        },
        getTotalStockMultiplier(satuan, mat_code, is_meta) {
            const {
                mc_to_ic_box,
                ic_to_box,
                mc_to_ic_slv,
                ic_to_slv,
            } = this.response_headers;

            let multiplier = 1;

            // Rhea HT Botol
            if (satuan === "IC") multiplier = 10;
            else if (satuan === "MC") multiplier = 100;

            // Rhea Meta Box
            if (is_meta && mat_code == "MT30") {
                if (satuan === "MC")
                    multiplier = mc_to_ic_box * ic_to_box || 120;
                if (satuan === "IC") multiplier = ic_to_box || 10;
            }

            // Rhea Meta Sleeve
            if (is_meta && mat_code == "MT10") {
                if (satuan === "MC")
                    multiplier = mc_to_ic_slv * ic_to_slv || 240;
                if (satuan === "IC") multiplier = ic_to_slv || 15;
            }

            return multiplier;
        },
        getItemStockDivider(satuan, mat_code, is_meta) {
            const {
                mc_to_ic_box,
                ic_to_box,
                mc_to_ic_slv,
                ic_to_slv,
            } = this.response_headers;

            let divider = 1;

            // Rhea HT Botol
            if (satuan === "MC") divider = 100;
            if (satuan === "IC") divider = 10;

            // Rhea Meta Box
            if (is_meta && mat_code == "MT30") {
                if (satuan === "MC") divider = mc_to_ic_box * ic_to_box || 120;
                if (satuan === "IC") divider = ic_to_box || 10;
            }

            // Rhea Meta Sleeve
            if (is_meta && mat_code == "MT10") {
                if (satuan === "MC") divider = mc_to_ic_slv * ic_to_slv || 240;
                if (satuan === "IC") divider = ic_to_slv || 15;
            }

            return divider;
        },
        getQtyStock(item) {
            const stock = item.total_stock || item.total || item.qty || 0;
            const divider = this.getItemStockDivider(
                item.satuan,
                item.mat_code,
                item.is_meta
            );

            const result = stock / divider;
            const commas = result % 1;

            return commas ? Number(result.toFixed(2)) : result;
        },

        getValueOfQty(value, satuan, item) {
            if (value === null) {
                return 0;
            } else {
                const { mat_code, is_meta } = item;
                const divider = this.getItemStockDivider(
                    satuan,
                    mat_code,
                    is_meta
                );

                const result = value / divider;
                const commas = result % 1;

                return commas ? Number(result.toFixed(2)) : result;
            }
        },

        getTotalAll(mc, ic, btl) {
            let totalBtlMc = 0;
            let totalBtlIc = 0;
            let total = 0;
            let totalBtlQr = btl;

            if (mc === null) {
                totalBtlMc = 0;
            } else {
                totalBtlMc = mc;
            }

            if (ic === null) {
                totalBtlIc = 0;
            } else {
                totalBtlIc = ic;
            }

            if (btl === null) {
                totalBtlQr = 0;
            } else {
                totalBtlQr = btl;
            }
            total =
                parseInt(totalBtlMc) +
                parseInt(totalBtlIc) +
                parseInt(totalBtlQr);
            return total;
        },

        getQtyCalculate(qty, item) {
            if (qty === null) return "0";

            const { type_box_name: satuan, is_meta, mat_code } = item;
            const parseSatuan = {
                "Master Carton": "MC",
                "Inner Carton": "IC",
            };
            const divider = this.getItemStockDivider(
                parseSatuan[satuan],
                mat_code,
                is_meta
            );
            const result = qty / divider;
            const commas = result % 1;

            return commas ? Number(result.toFixed(2)) : result;
        },
    },
};
