<template>
    <v-app>
        <v-main style="background: #FCFDFC" class="main-container">
            <router-view />
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: "App",

    data: () => ({
        //
    }),
};
</script>

<style>
@media print {
    .main-container {
        padding: 0px !important;
    }
}
</style>
