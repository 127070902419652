import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueHtmlToPaper from "vue-html-to-paper";
import VuePapaParse from "vue-papa-parse";
import JsonCSV from "vue-json-csv";
import axios from "axios";
import mixins from "./mixins";

import "./assets/css/materialdesignicons.min.css";

require("@/store/subscriber");
Vue.use(require("vue-moment"));
Vue.use(VuePapaParse);
Vue.mixin(mixins);
Vue.component("downloadCsv", JsonCSV);

Vue.config.productionTip = false;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

// Add a response interceptor
axios.interceptors.response.use(
    function(response) {
        const headerStore = store.getters["auth/custom_headers"];

        const mc_to_ic_box = response.headers["x-mc_to_ic_box"];
        const ic_to_box = response.headers["x-ic_to_box"];
        const box_to_capsule = response.headers["x-box_to_capsule"];

        const mc_to_ic_slv = response.headers["x-mc_to_ic_slv"];
        const ic_to_slv = response.headers["x-ic_to_slv"];
        const slv_to_capsule = response.headers["x-slv_to_capsule"];

        if (!headerStore && mc_to_ic_box) {
            store.dispatch("auth/customHeaders", {
                mc_to_ic_box,
                ic_to_box,
                box_to_capsule,
                mc_to_ic_slv,
                ic_to_slv,
                slv_to_capsule,
            });
        }

        return response;
    },
    function(error) {
        return Promise.reject(error);
    }
);

const options = {
    name: "_blank",
    specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
    styles: [
        "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
        "https://unpkg.com/kidlat-css/css/kidlat.css",
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
};
Vue.use(VueHtmlToPaper, options);

store.dispatch("auth/attempt", localStorage.getItem("token")).then(() => {
    new Vue({
        router,
        store,
        vuetify,
        render: (h) => h(App),
    }).$mount("#app");
});
